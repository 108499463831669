<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('payment-method-list')"
        @save="createItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12">
          <b-card>

            <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
              <hr>

                <b-row>
                  <b-col cols="12">
                    <validation-provider #default="validationContext"
                                         :name="$t('Account owner')" rules="required"
                    >
                      <b-form-group :label="$t('Account owner')">
                        <b-form-input v-model="user_sepa_debit.account_owner" :state="getValidationState(validationContext)"
                                      :placeholder="$t('Account owner')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <validation-provider #default="validationContext"
                                         :name="$t('IBAN')" rules="required"
                    >
                      <b-form-group :label="$t('IBAN')">
                        <b-form-input v-model="user_sepa_debit.sepa_debit.iban"
                                      :state="getValidationState(validationContext)"
                                      :placeholder="$t('DE## #### #### #### #### #### ##')"
                                      :name="$t('IBAN')"
                                      v-mask="'AA## #### #### #### #### #### ##'"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider #default="validationContext"
                                         :name="$t('BIC')" rules="required|bic"
                    >
                      <b-form-group :label="$t('BIC')">
                        <b-form-input v-model="user_sepa_debit.sepa_debit.bic"
                                      :state="getValidationState(validationContext)"
                                      :placeholder="$t('BIC')"
                                      :name="$t('BIC')"
                                      v-mask="['AAAAAAAA', 'AAAAAAAAAAA']"
                        />

                        <b-form-invalid-feedback>
                          {{ $t(validationContext.errors[0]) }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="success" type="submit"
                    >
                      {{ $t('Add Payment Method') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate'
import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {mask} from 'vue-the-mask'
import BCardActions from '@core/components/b-card-actions/BCardActions'
extend('bic', {
  validate(value) {
    const res1 = value.length == 8;
    const res2 = value.length == 11;

    return (res1 || res2);
  },
  params: [],
  message: 'The BIC must be have the length 8 or 11.'
});

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    required,
    alphaNum,
    email,
    BFormCheckbox,
    BFormRadio,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    Cleave,
  },
  directives: {
    Ripple,
    mask,
  },
  data() {
    return {
      user_sepa_debit: {
        account_owner: '',
        payment_type: 'sepa_debit',
        sepa_debit: {
          iban: '',
          bic: '',
        },
      },
      loaded: true,
    }
  },

  methods: {
    formatCvc(e) {
      return String(e)
          .substring(0, 3)
    },

    formatYear(e) {
      return String(e)
          .substring(0, 4)
    },

    createItem() {
      let url = `/experts/paymentmethods`

      this.$http.post(url, this.user_sepa_debit)
          .then(response => {
            this.$router.push(
                {
                  name: 'payment-method-list',
                },
                () => setTimeout(() => {
                  this.$bvToast.toast(this.$i18n.t('Payment Method successfully added'), {
                    title: this.$i18n.t('Success'),
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right'
                  })
                })
            )
          })
    },

    getValidationState({
                         dirty,
                         validated,
                         valid = null
                       }) {
      return dirty || validated ? valid : null
    }
    ,

  },

}
</script>

<style>
</style>
